<template>
    <div>
        
        <v-list
            subheader
            two-line
        >
            <v-subheader
                class= "pr-0"> 
                Tabuľky 
                <!-- <v-spacer/>
                <v-btn 
                    small 
                    depressed
                    color="error"
                    @click="deleteDialog = true"
                    :disabled="checkboxSelected.length < 1">
                     Vymazať 
                </v-btn> --> 
            </v-subheader>
            <v-list-item-group color="secondary">
                <template v-for="(table, index) in linky">             
                    <v-list-item :key="table.table_name" @click="openEditDialog(index)"> 
                        <v-list-item-avatar>
                            <v-icon
                                class="blue lighten-1"
                                dark
                            >
                                mdi-table
                            </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title v-text="table.title"></v-list-item-title>

                            <v-list-item-subtitle v-text="formatDate(table.created_at)"></v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-list-item-action-text class="action-text mr-5">Zobraziť a upraviť</v-list-item-action-text>
                        </v-list-item-action>
                        <!--<v-divider vertical/>
                        <v-list-item-action>
                            
                            <v-checkbox
                                v-model="checkboxSelected"
                                :value="table.table_name"
                                multiple
                            ></v-checkbox>

                        </v-list-item-action>-->

                    </v-list-item> 
                    <v-divider inset :key="index"> </v-divider>
                </template>
            </v-list-item-group>
        </v-list>
        <!--<v-btn class="my-btn" text block height="45" color="light-blue accent-4" @click="addDialog = true"> <strong>Pridať (+)</strong> </v-btn>-->

        <v-dialog
            v-model="editDialog"
            width="50%"
        >
            <v-card>
                <v-card-title class="headline justify-center">
                    Zmeniť názov tabuľky
                </v-card-title>

                <v-divider/>
                <v-form>
                    <v-col cols="4" offset="4">
                        <v-text-field 
                            v-model="nazovTabulkyText"
                            label="Názov tabuľky"
                            clearable
                            > 

                        </v-text-field>
                    </v-col>
                </v-form>


                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color=""
                    class="mr-2"
                    depressed
                    @click="editDialog = false"
                >
                    Zrušiť
                </v-btn>
                <v-btn
                    color="primary"
                    depressed
                    @click="saveEditDialog"
                >
                    Uložiť
                </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'

import { setTableName } from '@/data/api'
export default {
    name: "SettingsPlanovac",

    data(){
        return{
            editDialog: false,

            nazovTabulkyText: "",
            editedId: "",
        }
    },

    computed:{
        ...mapGetters(['linky', 'token', 'table_name_vuex']),
    },

    methods:{
        ...mapActions(['setLinkaName']),
        
        formatDate(date){
            date = new Date(date)

            return "Pridané " + date.toLocaleDateString("sk-SK")
        },

        openEditDialog(id){
            const linka = this.linky[id]
            this.nazovTabulkyText = linka.title
            this.editedId = id
            this.editDialog = true

        },

        async saveEditDialog(){
            const table_name = this.linky[this.editedId].table_name
            
            await axios.put(setTableName(table_name),
                {
                    nazov_linky: this.nazovTabulkyText
                },
                {
                    headers:{
                        Authorization: "Token " + this.token
                    }
                }).then(response => {
                    this.setLinkaName({id: this.editedId, title: response.data.nazov_linky})
                    
                }).catch(error =>{
                    console.error(error)
                })
                this.editDialog = false
        }

        /*saveAddDialog(){
            let allTables = [...this.linky]

            let newName = this.table_name_vuex

            const lastTable = this.linky[this.linky.length - 1].table_name
            if(typeof lastTable !== "undefined"){
                const lastNum = parseInt(lastTable.replace(/^\D+/g, ''))  // replace all leading non-digits with nothing
                newName = newName + (lastNum + 1)
            }else{
                newName = newName + 0
            }

            axios.post(getTableNames(), 
                {
                    'nazov_linky': this.nazovTabulkyText,
                    'nazov_tabulky': newName
                },
                {
                    headers:{
                        "Authorization": "Token " + this.token
                    },
                }
            ).then(response => {
                const data = response.data
                allTables.push({'title': data.nazov_linky, 'table_name': data.nazov_tabulky, 'created_at': data.datum_vytvorenia})

                this.setNewTable(!this.new_table)
                this.setLinky(allTables)
            })

            this.closeDialog()
        },*/

    }
}
</script>

<style scoped>
/*
.my-btn{
    border: dashed 1px;
    border-radius: 5px;
}*/
.action-text{
    font: 13px sans-serif;
}

</style>