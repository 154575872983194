<template>
    <v-container>
        <v-card
            width="90%"
            class="mx-auto"
            tile
        >
            <v-row>
                <v-col cols="3">

                    <v-list shaped>
                    <v-subheader>Nastavenia</v-subheader>
                    <v-list-item-group
                        v-model="selectedItem"
                        color="primary"
                    >
                        <v-list-item
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-table</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Plánovač</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                    </v-list>
                </v-col>
                <v-divider vertical inset> </v-divider>
                <v-col cols="8">
                    <SettingsPlanovac/>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import SettingsPlanovac from '@/views/components/settings/SettingsPlanovac.vue'

export default {
    name: "Settings",

    data(){
        return{
            selectedItem: 0
        }
    },

    components: {
        SettingsPlanovac
    }
}
</script>

<style>

</style>